<template>
  <div>
    <div class="header-additional">
      <div class="container">
        <div class="header-additional-block">
          <div class="header-additional__logo">
            <img src="/images/birgeoqy.svg" alt="">
            <div class="header-additional__desc">
              {{ $t('birgeoqy.Онлайн школа') }}
            </div>
          </div>
          <div class="header-additional__selects">
            <div class="select select--region">
              <el-select
                  @change="changeLang()"
                  :placeholder="$t('Qabilet.ВыберитеЯзык')"
                  v-model="locale"
                  filterable>
                <el-option
                    key="kz"
                    :label="$t('Qabilet.Казахский')"
                    value="kz">
                </el-option>
                <el-option
                    key="ru"
                    :label="$t('Qabilet.Русский')"
                    value="ru">
                </el-option>
              </el-select>
            </div>
            <div class="select select--region">
              <el-select
                  @change="updateInfo()"
                  :placeholder="$t('Qabilet.Регион')"
                  v-model="main_region_id"
                  filterable>
                <el-option
                    :key="''"
                    :label="$t('Qabilet.ПоказатьВсе')"
                    :value="''">
                </el-option>
                <el-option
                    v-for="item in regions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>
            <div class="select select--date">
              <el-date-picker
                  @change="updateInfo()"
                  v-model="main_date_from"
                  class="select--date"
                  type="date"
                  placeholder=""
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
              />
            </div>
            <div class="select select--date">
              <el-date-picker
                  @change="updateInfo()"
                  v-model="main_date_to"
                  class="select--date"
                  type="date"
                  placeholder=""
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
              />
            </div>
          </div>
        </div>
      </div>
    </div>


    <section class="statistic stat-regions" v-if="filteredSeriesTests[0].data.length > 0">
      <div class="container">
        <div class="birge_label">
          {{ $t('birgeoqy.Активные пользователи') }}

        </div>
        <div class="birge_stat-regions__row">
          <div class="stat-regions__chart">
            <div id="chart">
              <apexcharts ref="regionChart" width="100%" height="482px" type="bar"
                          :options="options"
                          :series="filteredSeriesTests"></apexcharts>
            </div>
          </div>
          <div class="birge_stat-regions__card">
            <div class="birge_stat-regions__show_all" @click="showAll">{{ $t('Qabilet.ПоказатьВсе') }}</div>
            <ol class="birge_stat-regions__list">
              <!--              <div style="cursor: pointer!important;margin-bottom: 12px;font-size: 14px;line-height: 16px;}"-->
              <!--                   @click="chartFilterRegion()"-->
              <!--                   v-bind:class="{ 'stat-regions__active-item': activeChartRegion==false }">-->
              <!--                {{ $t('Qabilet.ПоказатьВсе') }}-->
              <!--              </div>-->
              <li @click="activeRegionOnChart(item.id)"
                  class="active_user_chart"
                  v-bind:class="{ 'active_user_chart_selected': item.id === activeRegionId }"
                  v-for="item in regionsChart" :key="item.id+2">
                <div>
                  <div class="active_user_chart_item"

                  >
                    <div class="active_user_chart_item_id"
                         v-bind:class="{ 'active_user_chart_item_id_selected': item.id === activeRegionId }"
                    >{{ item.id }}
                    </div>
                    <div class="active_user_chart_item_name"
                         v-bind:class="{ 'active_user_chart_item_name_selected': item.id === activeRegionId }"
                    >{{ item.name }}
                    </div>
                  </div>
                  <div class="active_user_chart_item_count"
                       v-bind:class="{ 'active_user_chart_item_count_selected': item.id === activeRegionId }"
                  >{{ item.count | Numerize }}
                  </div>
                </div>
              </li>

            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="statistic stat-regions" v-if="false">
      <div class="container">
        <div class="birge_label">
          {{ $t('birgeoqy.Контингент(Ученики)') }}
        </div>
        <div class="stat-regions__row">
          <div class="stat-regions__charts" v-if="contingentLangSeries.length > 0">
            <div class="stat-regions__chart-row">
              <div class="birge_stat-regions__chart-block">
                <div class="birge_stat-regions__chart-block-title">
                  {{ $t('birgeoqy.Язык обучения') }}
                </div>
                <apexcharts
                    type="donut"
                    height="300"
                    :options="contingentLangChartOptions"
                    :series="contingentLangSeries"></apexcharts>
              </div>
              <div class="birge_stat-regions__chart-block">
                <div class="birge_stat-regions__chart-block-title">
                  {{ $t('birgeoqy.Возраст') }}
                </div>
                <apexcharts
                    type="donut"
                    height="300"
                    :options="contingentAgeChartOptions"
                    :series="contingentAgeSeries"></apexcharts>
              </div>
              <div class="birge_stat-regions__chart-block">
                <div class="birge_stat-regions__chart-block-title">
                  {{ $t('birgeoqy.Гендер') }}
                </div>
                <apexcharts
                    type="donut"
                    height="300"
                    :options="contingentGenderChartOptions"
                    :series="contingentGenderSeries"></apexcharts>
              </div>
              <div class="birge_stat-regions__chart-block">
                <div class="birge_stat-regions__chart-block-title">
                  {{ $t('birgeoqy.Класс') }}
                </div>
                <apexcharts
                    type="donut"
                    height="300"
                    :options="contingentClassChartOptions"
                    :series="contingentClassSeries"></apexcharts>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="statistic">
      <div class="container">
        <div class="birge_label">
          {{ $t('birgeoqy.Основные показатели Birge oqý') }}
        </div>
        <div class="birge_statistic__rows">
          <div class="birge_statistic__row">
            <div class="birge_statistic_card">
              <div class="birge_statistic-card__title">{{ $t('birgeoqy.Зарегистрировано') }}</div>
              <div class="birge_statistic-card__amount">{{ main.users.registered | Numerize }}</div>
            </div>
            <!--            <div class="statistic-card wrapper-birge-oqy statistic-card&#45;&#45;purple"-->
            <!--                 v-if="main.homeworkResponses !== undefined">-->
            <!--              <div class="statistic-card__title">{{ $t('birgeoqy.Количество решенных заданий') }}</div>-->
            <!--              <div class="statistic-card__amount">{{ main.homeworkResponses }}</div>-->
            <!--            </div>-->
            <div class="birge_statistic_card">
              <div class="birge_statistic-card__title">{{ $t('birgeoqy.Активные пользователи') }}</div>
              <div class="birge_statistic-card__amount">{{ main.users.active | Numerize }}</div>
            </div>
          </div>
        </div>

        <!--        <div class="statistic__rows">-->
        <!--          <div class="statistic__row">-->
        <!--            <div class="statistic-card wrapper-birge-oqy">-->
        <!--              <div class="birge_statistic-card__title">{{ $t('birgeoqy.Зарегистрировано') }}</div>-->
        <!--              <div class="statistic-card__amount">1 253</div>-->
        <!--            </div>-->
        <!--            &lt;!&ndash;            <div class="statistic-card wrapper-birge-oqy statistic-card&#45;&#45;purple"&ndash;&gt;-->
        <!--            &lt;!&ndash;                 v-if="main.homeworkResponses !== undefined">&ndash;&gt;-->
        <!--            &lt;!&ndash;              <div class="statistic-card__title">{{ $t('birgeoqy.Количество решенных заданий') }}</div>&ndash;&gt;-->
        <!--            &lt;!&ndash;              <div class="statistic-card__amount">{{ main.homeworkResponses }}</div>&ndash;&gt;-->
        <!--            &lt;!&ndash;            </div>&ndash;&gt;-->
        <!--            <div class="statistic-card wrapper-birge-oqy" >-->
        <!--              <div class="statistic-card__title">{{ $t('birgeoqy.Активные пользователи') }}</div>-->
        <!--              <div class="statistic-card__amount">9 123</div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </section>
    <section class="statistic">
      <div class="container">
        <div class="birge_label">
          {{ $t('birgeoqy.Количество подписок на курс') }}
        </div>
        <div class="birge_statistic__rows">
          <div class="birge_statistic__row">
            <div class="birge_statistic_card">
              <div class="birge_statistic-card__title">{{ $t('birgeoqy.Количество подписок') }}</div>
              <div class="birge_statistic-card__amount">{{ courseSubscriptions | Numerize }}</div>
            </div>
            <!--            <div class="statistic-card wrapper-birge-oqy statistic-card&#45;&#45;purple"-->
            <!--                 v-if="main.homeworkResponses !== undefined">-->
            <!--              <div class="statistic-card__title">{{ $t('birgeoqy.Количество решенных заданий') }}</div>-->
            <!--              <div class="statistic-card__amount">{{ main.homeworkResponses }}</div>-->
            <!--            </div>-->
            <div class="birge_statistic_card">
              <div class="birge_statistic-card__title">{{ $t('birgeoqy.Количество завершивших') }}</div>
              <div class="birge_statistic-card__amount">{{ finishedCourses | Numerize }}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container">
        <div class="birge_label">
          {{ $t('birgeoqy.Количество покупок по месяцам') }}
        </div>
        <div class="birge_stat-regions__row">
          <div class="stat-regions__chart w-100">
            <div id="chart">
              <apexcharts ref="regionChart" width="100%" height="482px" type="bar"
              :options="optionsOrders" :series="orderSeries"></apexcharts>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="stat-regions" v-if="filteredSeriesTestsCourses[0].data.length > 0">
      <div class="container">
        <div class="birge_label">
          {{ $t('birgeoqy.Топ 3 Курса') }}

        </div>
        <div class="birge_stat-regions__row">
          <div class="stat-regions__chart">
            <div id="chart">
              <apexcharts ref="regionChart" width="100%" height="482px" type="bar" :options="optionsCourses"
              :series="filteredSeriesTestsCourses"></apexcharts>
            </div>
          </div>
          <div class="birge_stat-regions__card">
            <div class="birge_stat-regions__show_all" @click="showAllCourses">{{ $t('Qabilet.ПоказатьВсе') }}</div>
            <ol class="birge_stat-regions__list">
              <!--              <div style="cursor: pointer!important;margin-bottom: 12px;font-size: 14px;line-height: 16px;}"-->
              <!--                   @click="chartFilterRegion()"-->
              <!--                   v-bind:class="{ 'stat-regions__active-item': activeChartRegion==false }">-->
              <!--                {{ $t('Qabilet.ПоказатьВсе') }}-->
              <!--              </div>-->
              <li @click="activeCourseOnChart(item.id)"
                  class="active_user_chart"
                  v-bind:class="{ 'active_user_chart_selected': item.id === activeCourseId }"
                  v-for="item in coursesChart" :key="item.id+2">
                <div>
                  <div class="active_user_chart_item"

                  >
                    <div class="active_user_chart_item_id"
                         v-bind:class="{ 'active_user_chart_item_id_selected': item.id === activeCourseId }"
                    >{{ item.id }}
                    </div>
                    <div class="active_user_chart_item_name"
                         v-bind:class="{ 'active_user_chart_item_name_selected': item.id === activeCourseId }"
                    >{{ item.name }}
                    </div>
                  </div>
                  <div class="active_user_chart_item_count"
                       v-bind:class="{ 'active_user_chart_item_count_selected': item.id === activeCourseId }"
                  >{{ item.count  | Numerize }}
                  </div>
                </div>
              </li>

            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="stat-regions">
      <div class="container">
        <div class="birge_label">
          {{ $t('birgeoqy.Ранг') }}

        </div>
        <div class="birge_stat-regions__row">
          <div class="stat-regions__chart">
            <div id="chart">
              <apexcharts ref="regionChart" width="100%" height="482px" type="bar" :options="optionsRanks"
                          :series="filteredSeriesRanksCourses"></apexcharts>
            </div>
          </div>
          <div class="birge_stat-regions__card">
            <div class="birge_stat-regions__show_all" @click="showAllRanks">{{ $t('Qabilet.ПоказатьВсе') }}</div>
            <ol class="birge_stat-regions__list_ranks">
              <!--              <div style="cursor: pointer!important;margin-bottom: 12px;font-size: 14px;line-height: 16px;}"-->
              <!--                   @click="chartFilterRegion()"-->
              <!--                   v-bind:class="{ 'stat-regions__active-item': activeChartRegion==false }">-->
              <!--                {{ $t('Qabilet.ПоказатьВсе') }}-->
              <!--              </div>-->
              <li @click="activeRankOnChart(item.name)"
                  class="active_user_chart_rank_block"
                  v-bind:class="{ 'active_user_chart_selected': item.name === activeRankId }"
                  v-for="item in ranksChart" :key="item.id+2">
                <div class="active_user_chart_rank" v-bind:class="{ 'active_user_chart_rank_selected': item.name === activeRankId }">
                  <div class="active_user_chart_item">
                    <div v-if="activeRankId !== item.name" :class="{
                    'active_user_chart_item_rank_icon_recruit': item.name === 'Новичок',
                    'active_user_chart_item_rank_icon_master': item.name === 'Мастер',
                    'active_user_chart_item_rank_icon_profi': item.name === 'Про',
                  }"></div>
                    <div v-if="activeRankId === item.name" :class="{
                    'active_user_chart_item_rank_icon_recruit_selected': activeRankId === 'Новичок',
                    'active_user_chart_item_rank_icon_master_selected': activeRankId === 'Мастер',
                    'active_user_chart_item_rank_icon_profi_selected': activeRankId === 'Про',
                  }"></div>
                    <div class="active_user_chart_item_rank_name"
                         v-bind:class="{ 'active_user_chart_item_rank_name_selected': item.name === activeRankId }"
                    >{{ item.name }}
                    </div>
                  </div>
                  <div class="active_user_chart_item_rank_count"
                       v-bind:class="{ 'active_user_chart_item_rank_count_selected': item.name === activeRankId }"
                  >{{ item.count  | Numerize }}
                  </div>
                </div>
              </li>

            </ol>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>
<script>

import VueApexCharts from "vue-apexcharts";

export default {
  name: 'Birge-oqy',
  components: {
    apexcharts: VueApexCharts,
  },
  data() {
    return {
      activeRankId: false,
      activeCourseId: false,
      activeRegionId: false,
      locale: this.$i18n.locale,
      currentHour: new Date().getHours(),
      currentMinutes: new Date().getMinutes(),
      main_date_from: '',
      main_date_to: '',
      main_region_id: '',
      contingent_region_id: '',
      staff_region_id: '',
      contingent_date: '',
      staff_date: '',
      analytics_date: '',
      regions: [],
      analytics: [],
      main: {
        users: {
          registered: 0,
          active: 0,
        },
        class: {},
        gender: {
          male: 0,
          female: 0,
        },
        ages: {},
        language: {}
      },
      searchContingentRegion: '',
      searchStaffRegion: '',
      contingent: [],
      contingentLangSeries: [],
      contingentLangChartOptions: {
        labels: [this.$t('Qabilet.Казахский'), this.$t('Qabilet.Русский')],
        legend: {position: 'bottom'},
        stroke: {show: false},
        colors: ['#C460C0', '#FFEFFF'],
        tooltip: {fillSeriesColor: false},
        dataLabels: {
          style: {colors: ['#FFEFFF', '#C460C0']},
          dropShadow: {enabled: false},
          formatter: (val, opt) => {
            let total = this.contingent.total
            let value = opt.w.globals.series[opt.seriesIndex]
            return value
          },
        },
      },
      contingentAgeSeries: [],
      contingentAgeChartOptions: {
        labels: ['11 лет', '12 лет ', '13 лет ', '14 лет'],
        legend: {position: 'bottom'},
        stroke: {show: false},
        colors: ['#305AA6', '#C460C0', '#CDD2FD', '#FFEFFF'],
        tooltip: {
          fillSeriesColor: false,
          y: {
            formatter: () => '',
            title: {
              formatter: (val, opt) => {
                let total = this.contingent.total
                let value = opt.w.globals.series[opt.seriesIndex]
                return val + ': ' + value
              },
            },
          },
        },
        dataLabels: {
          style: {colors: ['#ffffff', '#ffffff', '#305AA6', '#C460C0']},
          dropShadow: {enabled: false},
          formatter: (val, opt) => {
            let total = this.contingent.total
            let value = opt.w.globals.series[opt.seriesIndex]
            return value
          },
        },
      },
      contingentGenderSeries: [],
      contingentGenderChartOptions: {
        labels: [this.$t('birgeoqy.мальчики'), this.$t('birgeoqy.девочки')],
        legend: {position: 'bottom'},
        stroke: {show: false},
        colors: ['#305AA6', '#C460C0'],
        tooltip: {fillSeriesColor: false},
        dataLabels: {
          style: {colors: ['#FFFFFF', '#FFFFFF']},
          dropShadow: {enabled: false},
          formatter: (val, opt) => {
            let total = this.contingent.total
            let value = opt.w.globals.series[opt.seriesIndex]
            return value
          },
        },
      },
      contingentClassSeries: [],
      contingentClassChartOptions: {
        labels: [],
        legend: {position: 'bottom'},
        stroke: {show: false},
        colors: ['#C460C0', '#FFEFFF', '#473f95', '#fbeaea'],
        tooltip: {
          fillSeriesColor: false,
          y: {
            formatter: () => '',
            title: {
              formatter: (val, opt) => {
                let total = this.contingent.total
                let value = opt.w.globals.series[opt.seriesIndex]
                return val + ': ' + value
              },
            },
          },
        },
        dataLabels: {
          style: {colors: ['#ffffff', '#C460C0', '#ffffff', '#d23169']},
          dropShadow: {enabled: false},
          formatter: (val, opt) => {
            let total = this.contingent.total
            let value = opt.w.globals.series[opt.seriesIndex]
            return value
          },
        },
      },
      staff: [],
      staffCategorySeries: [],
      staffCategoryChartOptions: {
        labels: ['Без категории', 'Модератор', 'Эксперт', 'Исследователь', 'Мастер'],
        legend: {position: 'bottom'},
        stroke: {show: false},
        colors: ['#d23169', '#473f95', '#fbeaea', '#e3e2f2', '#FFCE20'],
        tooltip: {
          fillSeriesColor: false,
          y: {
            formatter: () => '',
            title: {
              formatter: (val, opt) => {
                let total = this.staff.total
                let value = opt.w.globals.series[opt.seriesIndex]
                return val + ': ' + value
              },
            },
          },
        },
        dataLabels: {
          style: {colors: ['#ffffff', '#ffffff', '#d23169', '#473f95']},
          dropShadow: {enabled: false},
          formatter: (val, opt) => {
            let total = this.staff.total
            let value = opt.w.globals.series[opt.seriesIndex]
            return value
          },
        },
      },
      staffExperienceSeries: [],
      staffExperienceChartOptions: {
        labels: [`1-3 года`, `3-5 ${this.$t('Qabilet.лет')}`, `5-10 ${this.$t('Qabilet.лет')}`, `${this.$t('birgeoqy.Свыше10')}`],
        legend: {position: 'bottom'},
        stroke: {show: false},
        colors: ['#d23169', '#473f95', '#fbeaea', '#e3e2f2'],
        tooltip: {
          fillSeriesColor: false,
          y: {
            formatter: () => '',
            title: {
              formatter: (val, opt) => {
                let total = this.staff.total
                let value = opt.w.globals.series[opt.seriesIndex]
                return val + ': ' + value
              },
            },
          },
        },
        dataLabels: {
          style: {colors: ['#ffffff', '#ffffff', '#d23169', '#473f95']},
          dropShadow: {enabled: false},
          formatter: (val, opt) => {
            let total = this.staff.total
            let value = opt.w.globals.series[opt.seriesIndex]
            return value
          },
        },
      },
      staffLangSeries: [],
      staffLangChartOptions: {
        labels: [this.$t('Qabilet.Казахский'), this.$t('Qabilet.Русский')],
        legend: {position: 'bottom'},
        stroke: {show: false},
        colors: ['#473f95', '#e3e2f2'],
        tooltip: {fillSeriesColor: false},
        dataLabels: {
          style: {colors: ['#e3e2f2', '#473f95']},
          dropShadow: {enabled: false},
          formatter: (val, opt) => {
            let total = this.staff.total
            let value = opt.w.globals.series[opt.seriesIndex]
            return val + ': ' + value
          },
        },
      },
      staffSubjectSeries: [],
      staffSubjectChartOptions: {
        labels: [`Математика`, `Русский язык`, `Английский язык`, `Казахский язык`],
        legend: {position: 'bottom'},
        stroke: {show: false},
        colors: ['#d23169', '#473f95', '#fbeaea', '#e3e2f2'],
        tooltip: {
          fillSeriesColor: false,
          y: {
            formatter: () => '',
            title: {
              formatter: (val, opt) => {
                let total = this.staff.total
                let value = opt.w.globals.series[opt.seriesIndex]
                return val + ': ' + value
              },
            },
          },
        },
        dataLabels: {
          style: {colors: ['#ffffff', '#ffffff', '#d23169', '#473f95']},
          dropShadow: {enabled: false},
          formatter: (val, opt) => {
            let total = this.staff.total
            let value = opt.w.globals.series[opt.seriesIndex]
            return val + ': ' + value
          },
        },
      },
      attendanceTeacherTodaySeries: [],
      attendanceTeacherTodayOptions: {
        stroke: {lineCap: 'round'},
        labels: [''],
        colors: ['#d23169'],
        plotOptions: {
          radialBar: {
            strokeWidth: '50%',
            hollow: {size: '60%'},
            track: {background: '#fbeaea'},
            dataLabels: {
              value: {show: false}, name: {offsetY: 10, fontSize: '44px'}
            }
          },
        },
      },
      attendanceTeacherThisweekSeries: [],
      attendanceTeacherThisweekOptions: {
        stroke: {lineCap: 'round'},
        labels: [''],
        colors: ['#d23169'],
        plotOptions: {
          radialBar: {
            strokeWidth: '50%',
            hollow: {size: '60%'},
            track: {background: '#fbeaea'},
            dataLabels: {
              value: {show: false}, name: {offsetY: 10, fontSize: '44px'}
            }
          },
        },
      },
      attendanceStudentTodaySeries: [],
      attendanceStudentTodayOptions: {
        stroke: {lineCap: 'round'},
        labels: [''],
        colors: ['#473f95'],
        plotOptions: {
          radialBar: {
            strokeWidth: '50%',
            hollow: {size: '60%'},
            track: {background: '#e3e2f2'},
            dataLabels: {
              value: {show: false}, name: {offsetY: 10, fontSize: '44px'}
            }
          },
        },
      },
      attendanceStudentThisweekSeries: [],
      attendanceStudentThisweekOptions: {
        stroke: {lineCap: 'round'},
        labels: [''],
        colors: ['#473f95'],
        plotOptions: {
          radialBar: {
            strokeWidth: '50%',
            hollow: {size: '60%'},
            track: {background: '#e3e2f2'},
            dataLabels: {
              value: {show: false}, name: {offsetY: 10, fontSize: '44px'}
            }
          },
        },
      },
      activateTeacherSeries: [],
      activateTeacherOptions: {
        stroke: {lineCap: 'round'},
        labels: [''],
        colors: ['#d23169'],
        plotOptions: {
          radialBar: {
            strokeWidth: '50%',
            hollow: {size: '60%'},
            track: {background: '#e3e2f2'},
            dataLabels: {
              value: {show: false}, name: {offsetY: 10, fontSize: '44px'}
            }
          },
        },
      },
      activateStudentSeries: [],
      activateStudentOptions: {
        stroke: {lineCap: 'round'},
        labels: [''],
        colors: ['#473f95'],
        plotOptions: {
          radialBar: {
            strokeWidth: '50%',
            hollow: {size: '60%'},
            track: {background: '#e3e2f2'},
            dataLabels: {
              value: {show: false}, name: {offsetY: 10, fontSize: '44px'}
            }
          },
        },
      },
      options: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        tooltip: {
          fillSeriesColor: false,
          y: {
            formatter: () => '',
            title: {
              formatter: (val, opt) => {
                let value = opt.w.globals.series[opt.seriesIndex][opt.dataPointIndex]
                return val + ': ' + '<b>' + value + '</b>'
              },
            },
          },
          x: {
            formatter: (val) => {
              const region = this.regionsChart.find(item => item.id === parseInt(val));
              return region.name
            },
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 5,
          }
        },
        legend: {
          show: true
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          type: 'numeric',
          tickAmount: 'dataPoints',
          decimalsInFloat: 0,
          labels: {
            show: true,
            rotateAlways: false,
            hideOverlappingLabels: true,
            showDuplicates: true,
            trim: false,
            minHeight: undefined,
            maxHeight: 120,
            style: {
              colors: [],
              fontSize: '14px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
            }
          },
        },
        colors: ['#CDD2FD', '#7CDEFF', '#04C2F4', '#10A2D3'],
        fill: {
          opacity: 1
        },
      },
      optionsCourses: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 5,
          }
        },
        legend: {
          show: true
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          type: 'category',
          tickAmount: 'dataPoints',
          decimalsInFloat: 0,
          labels: {
            show: true,
            rotateAlways: false,
            hideOverlappingLabels: true,
            showDuplicates: true,
            trim: false,
            minHeight: undefined,
            maxHeight: 120,
            style: {
              colors: [],
              fontSize: '14px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
            }
          },
          categories: [],
        },
        tooltip: {
          fillSeriesColor: false,
          y: {
            formatter: () => '',
            title: {
              formatter: (val, opt) => {
                let value = opt.w.globals.series[opt.seriesIndex][opt.dataPointIndex]
                return val + ': ' + '<b>' + value + '</b>'
              },
            },
          },
          x: {
            formatter: (val) => {
              const course = this.coursesChart.find(item => item.id === parseInt(val));
              return course.name
            },
          }
        },
        colors: ['#CDD2FD', '#7CDEFF', '#04C2F4', '#10A2D3'],
        fill: {
          opacity: 1
        },
      },
      optionsOrders: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 5,
          }
        },
        legend: {
          show: true
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          type: 'category',
          tickAmount: 'dataPoints',
          decimalsInFloat: 0,
          labels: {
            show: true,
            rotateAlways: false,
            hideOverlappingLabels: true,
            showDuplicates: true,
            trim: false,
            minHeight: undefined,
            maxHeight: 120,
            style: {
              colors: [],
              fontSize: '14px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
            }
          },
          categories: [],
        },
        colors: ['#CDD2FD', '#7CDEFF', '#04C2F4', '#10A2D3'],
        fill: {
          opacity: 1
        },
      },
      optionsRanks: {
        chart: {

          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 5,
            columnWidth: '20%',
          }
        },
        legend: {
          show: true
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          type: 'category',
          decimalsInFloat: 0,
          labels: {
            show: true,
            rotateAlways: false,
            hideOverlappingLabels: true,
            showDuplicates: true,
            trim: false,
            minHeight: undefined,
            maxHeight: 120,
            style: {
              colors: [],
              fontSize: '14px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
            }
          },
          categories: ['Новичок', 'Про', 'Мастер'],
        },
        colors: ['#CDD2FD', '#7CDEFF', '#04C2F4', '#10A2D3'],
        fill: {
          opacity: 1
        },
      },
      filteredSeriesRanksCourses: [{
        data: []
      }],
      filteredSeriesTestsCourses: [{
        data: []
      }],
      filteredSeriesTests: [{
        data: []
      }],
      seriesTests: [{
        name: "Количество активных пользователей",
        data: []
      }],
      seriesTestsCourses: [{
        name: "Количество пользователей",
        data: [{
          x: 1,
          y: 19008
        }, {
          x: 2,
          y: 8569
        }, {
          x: 3,
          y: 14986
        },
        ]
      }],
      rankNames: ['Новичок','Про','Мастер'],
      seriesTestsRanks: [{
        name: "Количество пользователей с данным рангом",
        data: []
      }],
      regionAllCount: 0,
      courseSubscriptions: 0,
      finishedCourses: 0,
      activeChartRegion: false,
      ranksChart: [],
      orderSeries: [],
      coursesChart: [{"id": 1, "name": "Web программист с нуля", "count": 19008}],
      regionsChart: [
        {"id": 1, "name": "\u0410\u0441\u0442\u0430\u043d\u0430", "count": 0}, 
        ],
      optionsNoStacked: {
        chart: {

          type: 'bar',
          height: 350,
          stacked: false,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        plotOptions: {},
        legend: {
          show: true,
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          labels: {
            show: false,
            rotateAlways: false,
            hideOverlappingLabels: true,
            showDuplicates: false,
            trim: false,
            minHeight: undefined,
            maxHeight: 120,
            style: {
              colors: [],
              fontSize: '7px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
            }
          },
          categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
        },
        colors: ['#343F68', '#7CDEFF', '#04C2F4', '#10A2D3'],
        fill: {
          opacity: 1
        },
      },
    }
  },
  filters: {
    Numerize: function (value) {
      if (value == 0) return '0'
      if (!value) return ''
      if (value <= 999) return value
      return value.toLocaleString().replace(',', ' ')
    }
  },
  mounted() {
    this.updateInfo()
  },
  methods: {

    foo(name) {

      if (this.activeRankId === name && name === 'Про') {
        return true
      } else {
        return false
      }

    },

    activeRankOnChart(id) {
      this.activeRankId = id
      let newData = this.seriesTestsRanks[0].data.map(item => {
        return {
          x: item.x,
          y: item.y
        }
      })
      let filteredNewData = newData.map(item => {
        if (item.x !== id) {
          return item.y = 0
        } else return item.y
      })
      this.filteredSeriesRanksCourses = [{data: filteredNewData}]
    },

    activeCourseOnChart(id) {
      this.activeCourseId = id
      let newData = this.seriesTestsCourses[0].data.map(item => {
        return {
          x: item.x,
          y: item.y
        }
      })
      let filteredNewData = newData.map(item => {
        if (item.x !== id) {
          return item.y = 0
        } else return item.y
      })
      this.filteredSeriesTestsCourses = [{data: filteredNewData}]
    },

    showAllRanks() {
      this.filteredSeriesRanksCourses = this.seriesTestsRanks
      this.activeRankId = false
    },

    showAll() {
      this.filteredSeriesTests = this.seriesTests
      this.activeRegionId = false
    },
    showAllCourses() {
      this.filteredSeriesTestsCourses = this.seriesTestsCourses
      this.activeCourseId = false
    },
    activeRegionOnChart(id) {
      this.activeRegionId = id
      let newData = this.seriesTests[0].data.map(item => {
        return {
          x: item.x,
          y: item.y
        }
      })
      let filteredNewData = newData.map(item => {
        if (item.x !== id.toString()) {
          return item.y = 0
        } else return item.y
      })
      this.filteredSeriesTests = [{data: filteredNewData}]
    },
    changeLang() {
      this.loading = true
      localStorage.setItem('locale', this.locale);
      this.$i18n.locale = this.locale;
      this.$router.go()
    },
    updateInfo() {
      this.main_date_to = this.main_date_to || ''
      this.main_date_from = this.main_date_from || ''
      
      this.$http.get(window.OSH_API_ROOT + '/api/dashboard/user-statistic' + '?date_from=' + this.main_date_from + '&date_to=' + this.main_date_to)
          .then((res) => {
            this.main = res.body;

            this.contingentLangSeries = Object.values(res.body.language).reverse()
            this.contingentAgeSeries = Object.values(res.body.ages)
            this.contingentGenderSeries = Object.values(res.body.gender)
            this.contingentClassSeries = Object.values(res.body.class)

            this.contingentAgeChartOptions.labels = Object.keys(res.body.ages).map(age => age + ' лет')
            this.contingentClassChartOptions.labels = Object.keys(res.body.class).map(age => age + ' ' +this.$t('birgeoqy.класс'))

          })

      this.$http.get(window.OSH_API_ROOT + '/api/dashboard/users-by-regions' + '?date_from=' + this.main_date_from + '&date_to=' + this.main_date_to)
          .then((res) => {
              res.body = res.body.sort((a, b) => a.region_id - b.region_id).sort((a, b) => b.count - a.count)
              this.seriesTests[0].data = []
              this.regionsChart = []
              res.body.forEach(region => {
                this.regionsChart.push({
                  id: region.region_id,
                  name: region.region_name,
                  count: region.count,
                })
                this.seriesTests[0].data.push({
                  x: (region.region_id || 'Нет данных').toString(),
                  y: region.count,
                  a: region.region_name
                })
              })
              this.filteredSeriesTests = this.seriesTests
          })
      this.$http.get(window.OSH_API_ROOT + '/api/dashboard/popular-courses' + '?date_from=' + this.main_date_from + '&date_to=' + this.main_date_to)
        .then((res) => {
            res.body = res.body.sort((a, b) => b.count - a.count)
            this.coursesChart = []
            this.seriesTestsCourses[0].data = []
            res.body.forEach(course => {
              this.seriesTestsCourses[0].data.push({
                  x: (course.course_id).toString(),
                  y: course.count
              })
              this.coursesChart.push({
                  id: course.course_id,
                  name: course.course_name,
                  count: course.count
              })
            })
            this.filteredSeriesTestsCourses = this.seriesTestsCourses
        })

      this.$http.get(window.OSH_API_ROOT + '/api/dashboard/users-by-rangs' + '?date_from=' + this.main_date_from + '&date_to=' + this.main_date_to)
        .then((res) => {
            this.seriesTestsRanks[0].data = []
            this.ranksChart = []
            res.body.forEach(rank => {
              this.seriesTestsRanks[0].data.push({
                  x: this.rankNames[rank.rang_id - 1],
                  y: rank.count
              })
              this.ranksChart.push({
                  id: rank.rang_id,
                  name: this.rankNames[rank.rang_id - 1],
                  count: rank.count
              })
            })
            this.filteredSeriesRanksCourses = this.seriesTestsRanks
        })

      this.$http.get(window.OSH_API_ROOT + '/api/dashboard/course-subscriptions' + '?date_from=' + this.main_date_from + '&date_to=' + this.main_date_to)
          .then((res) => {
            this.courseSubscriptions = res.body
          });
      this.$http.get(window.OSH_API_ROOT + '/api/dashboard/finished-courses' + '?date_from=' + this.main_date_from + '&date_to=' + this.main_date_to)
          .then((res) => {
            this.finishedCourses = res.body
          });
      this.$http.get(window.OSH_API_ROOT + '/api/dashboard/orders-by-month' + '?date_from=' + this.main_date_from + '&date_to=' + this.main_date_to)
          .then((res) => {
            const orderSeries = [];
            res.body.forEach((order, key) => {
              orderSeries.push({
                x: order.month,
                y: order.count,
              })
            })
            this.orderSeries = [{ name: "Количество покупок", data: orderSeries }]
          });
    },
    updateContingent(region) {
      if (parseInt(this.contingent_region_id) === region) {
        region = ''
      }
      this.contingent_region_id = region;
      this.updateInfo()
    },
    updateStaff(region) {
      if (parseInt(this.staff_region_id) === region) {
        region = ''
      }
      this.staff_region_id = region;
      this.updateInfo()
    },
    chartFilterRegion(item = false) {
      function sum(x) {
        var s = 0;
        for (var z = 0; z < x.length; z++) {
          s += x[z]
        }
        return s
      }

      this.activeChartRegion = item


      var seriesTests = []
      var profOrientation = []
      var kindergarten = []
      var step1 = []
      var step2 = []

      if (item == false) {
        for (var i = 0; i < this.regionsChart.length; i++) {
          profOrientation.push(this.regionsChart[i].prof_orientation)
          kindergarten.push(this.regionsChart[i].diagnostic_for_kindergarten_users)
          step1.push(this.regionsChart[i].diagnostic)
          step2.push(this.regionsChart[i].second_step_diagnostic)
        }
      }
      if (item != false) {
        item = item - 1
        for (var x = 0; x < this.regionsChart.length; x++) {
          if (x == item) {
            profOrientation.push(this.regionsChart[item].prof_orientation)
            kindergarten.push(this.regionsChart[item].diagnostic_for_kindergarten_users)
            step1.push(this.regionsChart[item].diagnostic)
            step2.push(this.regionsChart[item].second_step_diagnostic)
          } else {
            profOrientation.push(0)
            kindergarten.push(0)
            step1.push(0)
            step2.push(0)
          }

        }
      }


      this.seriesTests = seriesTests
      this.regionAllCount = sum(profOrientation) + sum(kindergarten) + sum(step1) + sum(step2)

    },
  },
  computed: {
    contingentRegionSearch() {
      return this.regions.filter(region => {
        return !this.searchContingentRegion ||
            region.name.toLowerCase().indexOf(this.searchContingentRegion.toLowerCase()) > -1
      })
    },
    staffRegionSearch() {
      return this.regions.filter(region => {
        return !this.searchStaffRegion ||
            region.name.toLowerCase().indexOf(this.searchStaffRegion.toLowerCase()) > -1
      })
    },
  }
}
</script>
<style lang="css" scoped>
.stat-regions--bo .stat-regions__charts {
  width: 100% !important;
}

.statistic__rows {
  display: flex;
  justify-content: center;
}

.applications__period-date {
  width: 100%;
  max-width: 100%;
}

.stat-regions--bo .stat-regions__list {
  width: 400px;
}

.stat-regions--bo .search__input {
  width: 100%;
}

@media screen and (max-width: 1380px) {
  .stat-regions--bo .stat-regions__charts {
    width: 100%;
    margin-bottom: 32px;
  }

  .stat-regions--bo .stat-regions__card {
    margin: 0 auto;
  }

  stat-regions--bo .stat-regions__row {
    flex-direction: column;
  }
}

@media screen and (max-width: 991px) {
  .header-additional__selects .select:last-child {
    margin-right: 10px;
  }

  .select {
    margin: 0.5rem;
  }

  .select--date {
    margin-left: 8px;
  }

  .header-additional__selects {
    flex-wrap: wrap;
    justify-content: end;
  }

  .attendance__card {
    margin-top: 1rem;
  }

  .attendance__cards {
    flex-direction: column;
  }

  .attendance__card {
    width: 100%;
  }

  .stat-regions__chart-row {
    flex-wrap: wrap;
  }

  .stat-regions__chart-block {
    width: 100%;
  }

  .stat-regions__row {
    flex-direction: column;
  }

  .statistic-card {
    width: 100%;
    margin-bottom: 1rem;
  }

  .statistic__row {
    width: 100%;
    flex-direction: column
  }
}

</style>
